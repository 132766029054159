import React from "react"
import Layout from "../components/Layout"
import CompassBGImage from "../components/CompassBGImage"
import DouroHouseImg from "../components/imageComponents/DouroHouseImg"

export default function () {
  return (
    <Layout>
      <CompassBGImage
        style={{
          backgroundPosition: `top`,
        }}
      >
        <div className="hero-image-canvas"></div>
      </CompassBGImage>

      <div className="contents-wrapper">
        <div className="text-container">
          <h1>Contact</h1>
          <p>
            If you would like to book an appointment, or if you have any
            questions about the service, please contact Sarah by email or phone.
          </p>
          <p>
            <a href="tel:+61 476 940 998" target="_blank">
              Phone: 0476 940 998
            </a>
          </p>
          <p>
            <a
              href="mailto:sarah@hartleyclinicalpsychology.com"
              target="_blank"
            >
              Email: sarah@hartleyclinicalpsychology.com
            </a>
          </p>
          <p>
            <a href="tel:+61 8 849 032 49" target="_blank">
              Fax: 08 849 032 49
            </a>
          </p>
          <div className="operating-hours-wrapper">
            <h2>Hours of operation</h2>

            <div className="operating-hours">
              <div>Wednesday</div>
              <div>Daytime and evening</div>
              <div>Thursday</div>
              <div>Daytime</div>
              <div>Friday</div>
              <div>Daytime</div>
            </div>
          </div>
          <h2>Location Details</h2>
          <p>Douro House | 14 Douro Place | West Perth | WA 6005</p>
          <p>
            <a
              href="http://psychologistsinperth.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Check out the Douro House website
            </a>
          </p>
          <div className="standout-box urgent-help">
            <p>
              Please note: if you need urgent support or feel unsafe, you should
              contact
              <ul className="hanging-indent-list">
                <li>
                  <i className="fa fa-chevron-right"></i>
                  Lifeline on <a href="tel:+13 11 14">13 11 14</a> or
                </li>
                <li>
                  <i className="fa fa-chevron-right"></i>
                  the Mental Health Emergency Response Line (MERL) on{" "}
                  <a href="tel:1300 555 788">1300 555 788</a>
                </li>
              </ul>
            </p>
          </div>

          <h2>Getting here by car</h2>
          <p>
            Metered parking is available in Douro Place and in nearby streets.
          </p>

          <p>
            There is also a City of Perth carpark nearby in Wellington Street.
          </p>

          <h2>Getting here by public transport</h2>

          <p>Douro House is a five minute walk from City West Train station.</p>
          <p>
            The yellow and green CAT buses provide a regular service to the
            area.
          </p>
        </div>

        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13542.318996837574!2d115.83316099564281!3d-31.945165201167146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32a530ecbd9f57%3A0x9e5ac512a6f20e8b!2s14%20Douro%20Pl%2C%20West%20Perth%20WA%206005!5e0!3m2!1sen!2sau!4v1611122141930!5m2!1sen!2sau"
            className="map"
            aria-hidden="false"
          />
        </div>

        <DouroHouseImg style={{}} className={"douro-img"} />
      </div>
    </Layout>
  )
}
